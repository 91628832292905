<template>
  <el-select
    v-model="inspectionModel"
    @change="inspectionChanged"
    autocomplete="new-password"
    :placeholder="placeholder ? placeholder : $t('COMMON.INSPECTION')"
    :filterable="filterable"
    :multiple="false"
    :disabled="disabled"
    remote
    :remote-method="getInspections"
    :loading="loading"
  >
    <el-option
      v-if="showAll"
      :value="null"
      :label="placeholder ? placeholder : $t('COMMON.ALL_INSPECTIONS')"
    >
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="inspection in inspections"
      :key="inspection.id"
      :value="inspection.id"
      :label="`${inspection.code} ${inspection.inspectionForm?.name ?? ''}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";
import { INSPECTION_STATUS_COMPLETED } from "@/constants/inspections";

export default {
  name: "inspection-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    placeholder: {
      type: String,
      default: null,
      description: "Placeholder of input",
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    inspection: {
      type: String,
      default: null,
      description: "Inspection id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterOrganizationNull: {
      type: Boolean,
      default: null,
      description: "Filter only null organizations",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
    filterResellerNull: {
      type: Boolean,
      default: null,
      description: "Filter only null resellers",
    },
    filterStatus: {
      type: String,
      default: INSPECTION_STATUS_COMPLETED,
      description: "Inspection status",
    },
  },

  data() {
    return {
      inspectionModel: this.inspection,
      inspections: {},
    };
  },

  setup() {},

  created() {
    this.getInspections(null, this.inspection);
  },

  methods: {
    async getInspections(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "-updated_at",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { with_ids: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }
        if (this.filterOrganizationNull) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: null,
            },
          };
        }
        if (this.filterReseller) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              reseller: this.filterReseller,
            },
          };
        }
        if (this.filterResellerNull) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              reseller: null,
            },
          };
        }
        if (this.filterStatus) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.filterStatus,
            },
          };
        }

        await this.$store.dispatch("inspections/list", params);
        const inspectionsArr = await this.$store.getters["inspections/list"];
        this.inspections = {};
        inspectionsArr.forEach((inspection) => {
          this.inspections[inspection.id] = inspection;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    inspectionChanged(inspection) {
      this.$emit("inspectionChanged", inspection);
    },
  },

  watch: {
    inspection(inspection) {
      if (inspection) {
        this.inspectionModel = inspection;
        if (inspection !== this.inspectionModel) {
          this.getInspections(null, inspection);
        }
      } else {
        this.inspectionModel = null;
      }
    },
    filterOrganization(filterOrganization) {
      this.getInspections();
    },
    filterOrganizationNull(filterOrganization) {
      this.getInspections();
    },
    filterReseller(filterReseller) {
      this.getInspections();
    },
    filterResellerNull(filterReseller) {
      this.getInspections();
    },
  },
};
</script>
