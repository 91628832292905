<template>
  <div>
    <slot name="header">
      <h3 v-if="header" class="table-title">{{ header }}</h3>
    </slot>

    <div class="buttons-wrapper margin" v-if="showTabs">
      <!-- <base-button
        class=""
        :class="{ active: !selectedStatus }"
        @click="changeSelectedStatus(null)"
      >
        <span class="text"> {{ $t("REPAIRS.ALL") }} </span>
        <span class="number"> {{ counters.all }} </span>
      </base-button> -->
      <base-button
        class="todo"
        :class="{
          active:
            selectedStatus?.includes(REPAIR_STATUS_ASSIGNED) &&
            selectedStatus?.includes(REPAIR_STATUS_PENDING),
        }"
        @click="
          changeSelectedStatus([REPAIR_STATUS_ASSIGNED, REPAIR_STATUS_PENDING])
        "
      >
        <span class="text"> {{ $t("COMMON.TODO") }} </span>
        <span class="number"> {{ counters.todo }} </span>
      </base-button>
      <base-button
        class="DRAFT"
        :class="{ active: selectedStatus == REPAIR_STATUS_DRAFT }"
        @click="changeSelectedStatus(REPAIR_STATUS_DRAFT)"
      >
        <span class="text"> {{ $t("COMMON.DRAFTS") }} </span>
        <span class="number"> {{ counters.draft }} </span>
      </base-button>
      <base-button
        class="COMPLETED"
        :class="{ active: selectedStatus == REPAIR_STATUS_COMPLETED }"
        @click="changeSelectedStatus(REPAIR_STATUS_COMPLETED)"
      >
        <span class="text">
          {{ $t("COMMON.COMPLETED") }}
        </span>
        <span class="number"> {{ counters.completed }} </span>
      </base-button>
      <base-button
        class="CANCELED"
        :class="{ active: selectedStatus == REPAIR_STATUS_CANCELED }"
        @click="changeSelectedStatus(REPAIR_STATUS_CANCELED)"
      >
        <span class="text">
          {{ $t("COMMON.CANCELED") }}
        </span>
        <span class="number"> {{ counters.canceled }} </span>
      </base-button>
    </div>

    <div class="list-table-inner-body">
      <div class="list-table-inner-body-inner">
        <el-table
          class="align-items-center table-flush repairs"
          header-row-class-name="thead-light has-image"
          :data="repairs"
          @sort-change="sortChange"
          row-class-name="cursor-pointer"
          @row-click="
            (row) => {
              viewRepair(row);
            }
          "
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" />
          </div>

          <el-table-column width="75" v-if="canViewColumn('vehicle')">
            <template v-slot="{ row }">
              <div v-if="row.id" class="image">
                <img
                  v-if="row.vehicle?.gallery[0]"
                  :src="row.vehicle?.gallery[0]"
                  class="avatar rounded-circle"
                />
                <span class="default-icon">
                  <i class="far fa-camera"></i>
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="canViewColumn('vehicle')"
            :label="$t('COMMON.VEHICLE')"
            prop="vehicle"
            min-width="250"
          >
            <template v-slot="{ row }">
              <div class="vehicle">
                <ul class="no-line">
                  <li>
                    {{ row.vehicle?.make }} {{ row.vehicle?.model }}
                    {{ row.vehicle?.model_year }}
                  </li>
                </ul>
                <ul>
                  <li v-if="row.vehicle?.series">{{ row.vehicle?.series }}</li>
                  <li class="line" v-if="row.vehicle?.series">|</li>
                  <li v-if="row.vehicle?.color">{{ row.vehicle?.color }}</li>
                  <li class="line" v-if="row.vehicle?.color">|</li>
                  <li>
                    {{ row.vehicle?.odometer }} {{ row.vehicle?.odometer_unit }}
                  </li>
                </ul>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="canViewColumn('vin')"
            :label="$t('REPAIRS.STOCK_NIV')"
            prop="status"
            min-width="230"
          >
            <template v-slot="{ row }">
              <ul>
                <li>{{ row.stock }}</li>
                <li>
                  <copy-element :element="row?.vehicle?.vin" />
                </li>
              </ul>
            </template>
          </el-table-column>

          <el-table-column
            v-if="canViewColumn('customer')"
            :label="$t('REPAIRS.CLIENT_WORK_ORDER')"
            prop="client"
            min-width="200"
          >
            <template v-slot="{ row }">
              <ul>
                <li>{{ $objectDenomination(row.customer) }}</li>
                <!-- <li>{{ row.workOrder }}</li> -->
              </ul>
            </template>
          </el-table-column>

          <el-table-column
            v-if="canViewColumn('model')"
            :label="$t('REPAIRS.REPAIR')"
            prop="code"
            sortable="custom"
            min-width="150"
          >
            <template v-slot="{ row }">
              <div class="code">
                <ul>
                  <li>{{ row?.code }}</li>
                  <li>{{ row?.items_count }} item(s)</li>
                </ul>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="canViewColumn('status')"
            :label="$t('COMMON.STATUS')"
            prop="status"
            min-width="150"
          >
            <template v-slot="{ row }">
              <repair-status-badge :status="row.status" :repair="row" />
            </template>
          </el-table-column>

          <el-table-column
            v-if="canViewColumn('mechanic')"
            :label="$t('COMMON.MECANIC')"
            prop="vehicle_series"
            min-width="220"
          >
            <template v-slot="{ row }">
              <user :user="row.startedBy" />
            </template>
          </el-table-column>

          <el-table-column
            v-if="canViewColumn('updated_at')"
            :label="$t('COMMON.MODIFY')"
            prop="updated_at"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span> {{ $timeZoneDateFormat(row.updated_at, "L LT") }} </span>
            </template>
          </el-table-column>

          <el-table-column
            v-if="canViewColumn('days')"
            :label="$t('COMMON.DAYS')"
            min-width="150"
          >
            <template v-slot="{ row }">
              <span>{{ row?.repair_days }}</span>
            </template>
          </el-table-column>

          <el-table-column fixed="right" min-width="120">
            <template v-slot="{ row }">
              <div class="table-actions">
                <el-tooltip :content="$t('COMMON.VIEW')" placement="top">
                  <a
                    type="text"
                    class="table-action view"
                    data-toggle="tooltip"
                    @click="viewRepair(row)"
                  >
                    <i
                      class="fa-light fa-arrow-up-right-and-arrow-down-left-from-center"
                    ></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('COMMON.DELETE')"
                  placement="top"
                  :class="{ disabled: !canDeleteRepair(row) }"
                >
                  <a
                    type="text"
                    class="table-action delete"
                    data-toggle="tooltip"
                    @click="deleteRepair(row)"
                  >
                    <i class="fa-light fa-trash-can"></i>
                  </a>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div
      class="list-table-inner-footer"
      v-if="showPagination || total > pagination.perPage"
    >
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}
        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import _, { cloneDeep } from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { BasePagination } from "@/components";
import RepairStatusBadge from "./RepairStatusBadge.vue";
import CopyElement from "@/components/CopyElement.vue";
import User from "@/components/User.vue";
import {
  REPAIR_STATUS_DRAFT,
  REPAIR_STATUS_PENDING,
  REPAIR_STATUS_IN_PROGRESS,
  REPAIR_STATUS_VALIDATED,
  REPAIR_STATUS_COMPLETED,
  REPAIR_STATUS_CANCELED,
  REPAIR_STATUS_ASSIGNED,
} from "@/constants/repairs";
import repairPermissionsMixin from "@/mixins/repair-permissions-mixin";
import repairsService from "../../../../../store/services/repairs-service";

export default {
  name: "repair-table",

  components: {
    RepairStatusBadge,
    CopyElement,
    User,
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [repairPermissionsMixin],

  props: {
    header: {
      type: String | Boolean | null | undefined,
      default: null,
    },
    showTabs: {
      type: Boolean,
      default: false,
    },
    // pagination: {
    //   type: Object,
    //   default: () => ({
    //     perPage: 20,
    //     currentPage: 1,
    //     perPageOptions: [20, 50, 100, 500],
    //   }),
    // },
    selectedDateRange: {
      type: Array | String | Date | null,
      default: null,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    filterStatus: {
      type: String,
      default: null,
      description: "Status",
    },
    excludeStatus: {
      type: String,
      default: null,
    },
    selectedCompletedBy: {
      type: String,
      default: null,
    },
    selectedCreator: {
      type: String,
      default: null,
    },
    selectedCustomer: {
      type: String,
      default: null,
    },
    filterVehicle: {
      type: String,
      default: null,
      description: "Vehicle id",
    },
    selectedVehicleModelYear: {
      type: String,
      default: null,
    },
    selectedVehicleMake: {
      type: String,
      default: null,
    },
    selectedVehicleModel: {
      type: String,
      default: null,
    },
    selectedVehicleSeries: {
      type: String,
      default: null,
    },
    selectedOrganization: {
      type: String,
      default: null,
    },
    selectedInspection: {
      type: String,
      default: null,
    },
    query: {
      type: String,
      default: null,
    },
    columns: {
      type: Array | null,
      default: () => [
        "vehicle",
        "vin",
        "customer",
        "model",
        "status",
        "mechanic",
        "updated_at",
        "days",
      ],
    },
  },

  data() {
    const selectedStatus = this.filterStatus;

    return {
      selectedRows: [],
      sort: "-updated_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      repairs: [],
      loading: true,
      selectedStatus,
      selectedAssignedToGroup: false,
      REPAIR_STATUS_DRAFT,
      REPAIR_STATUS_IN_PROGRESS,
      REPAIR_STATUS_COMPLETED,
      REPAIR_STATUS_CANCELED,
      REPAIR_STATUS_ASSIGNED,
      REPAIR_STATUS_PENDING,
      counters: {
        createdByMe: 0,
        todo: 0,
        all: 0,
        draft: 0,
        pending: 0,
        assigned: 0,
        inProgress: 0,
        completed: 0,
        canceled: 0,
        assignedToMe: 0,
      },
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    params() {
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.query ? { search: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
        include: "vehicle,customer,organization,startedBy",
      };

      if (this.selectedStatus) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            status: this.selectedStatus,
          },
        };
      }

      if (this.selectedAssignedToGroup) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            assignedToRoles: this.selectedAssignedToGroup,
          },
        };
      }

      if (this.excludeStatus) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            excludeStatus: this.excludeStatus,
          },
        };
      }

      if (this.selectedDateRange && this.selectedDateRange.length) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            created_at: [
              this.selectedDateRange[0] + " 00:00:00",
              this.selectedDateRange[1] + " 23:59:59",
            ],
          },
        };
      }

      if (this.selectedCompletedBy) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            completedBy: this.selectedCompletedBy,
          },
        };
      }

      if (this.selectedCreator) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            creator: this.selectedCreator,
          },
        };
      }

      if (this.selectedCustomer) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            customer: this.selectedCustomer,
          },
        };
      }

      if (this.filterVehicle) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            vehicle: this.filterVehicle,
          },
        };
      }

      if (this.selectedVehicleModelYear) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            vehicle: {
              ...params.filter?.vehicle,
              model_year: this.selectedVehicleModelYear,
            },
          },
        };
      }

      if (this.selectedVehicleMake) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            vehicle: {
              ...params.filter?.vehicle,
              make: this.selectedVehicleMake,
            },
          },
        };
      }

      if (this.selectedVehicleModel) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            vehicle: {
              ...params.filter?.vehicle,
              model: this.selectedVehicleModel,
            },
          },
        };
      }

      if (this.selectedVehicleSeries) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            vehicle: {
              ...params.filter?.vehicle,
              series: this.selectedVehicleSeries,
            },
          },
        };
      }

      if (this.selectedOrganization) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            vehicle: {
              ...params.filter?.vehicle,
              organization: this.selectedOrganization,
            },
          },
        };
      }

      if (this.selectedInspection) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            inspection: this.selectedInspection,
          },
        };
      }

      return params;
    },
  },

  async created() {
    await this.getList();

    if (this.showTabs) {
      this.getCounters();
    }
  },

  methods: {
    async getList() {
      this.repairs = [];
      this.loading = true;

      try {
        let params = this.params;

        const { list, meta } = await this.$store.dispatch(
          "repairs/list",
          params
        );

        this.repairs = list;
        this.total = meta.page.total;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    changeSelectedStatus(
      status,
      assignedTo = null,
      creator = null,
      group = false
    ) {
      this.selectedStatus = status;
      // this.selectedInspectionAssignedTo = assignedTo;
      // this.selectedInspectionCreator = creator;
      this.selectedAssignedToGroup = group;
    },

    async getCounters() {
      const {
        [REPAIR_STATUS_ASSIGNED]: assigned = 0,
        [REPAIR_STATUS_CANCELED]: canceled = 0,
        [REPAIR_STATUS_COMPLETED]: completed = 0,
        [REPAIR_STATUS_IN_PROGRESS]: inProgress = 0,
        [REPAIR_STATUS_PENDING]: pending = 0,
        [REPAIR_STATUS_DRAFT]: draft = 0,
      } = await repairsService.getCount(this.params);

      this.counters = {
        ...this.counters,
        draft,
        pending,
        assigned,
        todo: pending + assigned,
        inProgress,
        completed,
        canceled,
      };
    },

    getParams(status = null, assignedTo = null, creator = null, group = false) {
      let params = {
        filter: cloneDeep(this.params.filter),
        page: {
          number: 1,
          size: 1,
        },
        include: "",
      };

      if (assignedTo) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            assignedToUsers: assignedTo,
          },
        };
      }
      if (group) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            assignedToRoles: group,
          },
        };
      }
      if (creator) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            creator: creator,
          },
        };
      }
      if (status) {
        params = {
          ...params,
          filter: {
            ...params.filter,
            status: status,
          },
        };
      } else {
        delete params.filter.status;
      }

      return params;
    },

    canViewColumn(column) {
      if (!this.columns || !Array.isArray(this.columns)) return true;

      return this.columns?.includes(column);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    viewRepair(repair) {
      this.$emit("onViewRepair", repair);
    },

    deleteRepair(repair) {
      if (this.canDeleteRepair(repair)) this.$emit("onDeleteRepair", repair);
    },
  },

  watch: {
    params: {
      handler: async function (value, oldValue) {
        await this.getList();

        if (this.showTabs && value.filter.status == oldValue.filter.status) {
          this.getCounters();
        }
      },
      immediate: false,
      deep: true,
    },

    filterStatus: function (value) {
      this.selectedStatus = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>
