<template>
  <div class="list-table-inner">
    <div class="list-table-inner-header">
      <div class="search">
        <div class="search-add">
          <base-button
            class="add"
            icon
            size="sm"
            @click="addRequest"
            v-if="$currentUserCan($permissions.PERM_CREATE_REQUESTS)"
          >
            <span class="btn-inner--icon">
              <i class="fa-regular fa-circle-plus"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("REQUESTS.ADD_REQUEST") }}
            </span>
          </base-button>
        </div>
        <div
          class="quick-request"
          v-if="$currentUserCan($permissions.PERM_CREATE_REQUESTS)"
        >
          <el-tooltip content="Demande rapide" placement="top">
            <base-button
              class="fast-request-button"
              icon
              size="sm"
              @click="addQuickRequest"
            >
              <img src="/img/fast.svg" alt="icon" />
            </base-button>
          </el-tooltip>
        </div>
        <div class="search-form">
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fal fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
          <button
            @click="
              () => {
                showAdvancedFilter = !showAdvancedFilter;
              }
            "
          >
            <i class="far fa-sliders-h"></i>
          </button>
        </div>
        <!--<notification-subscription
          v-if="$currentUserCan($permissions.PERM_VIEW_REQUESTS)"
          :objectType="'requests'"
          :events="{
            CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
            UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
            DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
          }"
        />-->
      </div>
      <transition name="slide">
        <div class="advanced-search" v-if="showAdvancedFilter">
          <div class="advanced-search-item pagination-select">
            <el-select
              class="select-primary"
              v-model="pagination.perPage"
              :placeholder="$t('COMMON.PER_PAGE')"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="advanced-search-item" v-if="!filterPriority">
            <el-select class="select-primary" v-model="selectedPriority">
              <el-option
                class="select-primary"
                key="0"
                :label="`${$t('COMMON.PRIORITY')}...`"
                :value="null"
              />
              <el-option
                v-for="priority in requestPrioritiesOption"
                class="select-primary"
                :key="priority"
                :label="$t(`REQUESTS.REQUEST_PRIORITY_${priority}`)"
                :value="priority"
              />
            </el-select>
          </div>
          <div class="advanced-search-item" v-if="!filterStatus">
            <el-select class="select-primary" v-model="selectedStatus">
              <el-option
                class="select-primary"
                key="0"
                :label="`${$t('COMMON.STATUS')}...`"
                :value="null"
              />
              <el-option
                v-for="status in requestStatusesOption"
                class="select-primary"
                :key="status"
                :label="$t(`REQUESTS.REQUEST_STATUS_${status}`)"
                :value="status"
              />
            </el-select>
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterAssignedTo &&
              $currentUserCan($permissions.PERM_VIEW_ANY_USERS)
            "
          >
            <user-selector
              :placeholder="$t('REQUESTS.ASSIGNED_TO')"
              @userChanged="(userId) => (selectedAssignedTo = userId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterCreator &&
              $currentUserCan($permissions.PERM_VIEW_ANY_USERS)
            "
          >
            <user-selector
              :placeholder="$t('REQUESTS.CREATOR')"
              @userChanged="(userId) => (selectedCreator = userId)"
            />
          </div>
          <div class="advanced-search-item">
            <base-input :placeholder="`${$t('COMMON.TAGS')}`">
              <tags-selector
                @tagsChanged="
                  (tags) => {
                    selectedTags = tags;
                  }
                "
                :organization="selectedOrganization"
              />
            </base-input>
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterOrganization &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
          >
            <organization-selector
              @organizationChanged="
                (organizationId) => (selectedOrganization = organizationId)
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
          >
            <base-input :placeholder="`${$t('COMMON.LOCATIONS')}`">
              <locations-selector
                @locationsChanged="
                  (locations) => (selectedLocations = locations)
                "
              />
            </base-input>
          </div>
          <div class="advanced-search-item update">
            <base-button class="add" icon size="sm" @click="getListDebounced">
              <span class="btn-inner--icon">
                <i class="far fa-sync-alt"></i>
              </span>
              <span class="btn-inner--text">{{ $t("COMMON.REFRESH") }}</span>
            </base-button>
          </div>
        </div>
      </transition>
    </div>
    <div class="list-table-inner-body">
      <div class="list-table-inner-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light has-image"
          :data="requests"
          @sort-change="sortChange"
          row-class-name="cursor-pointer"
          @row-click="
            (row) => {
              viewRequest(row);
            }
          "
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" />
          </div>

          <el-table-column width="75">
            <template v-slot="{ row }">
              <div class="image">
                <img
                  v-if="row.vehicle?.gallery?.length > 0"
                  :src="row.vehicle?.gallery[0]"
                  class="avatar rounded-circle"
                />
                <span class="default-icon">
                  <i class="far fa-camera"></i>
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column :label="$t('COMMON.VEHICLE')" min-width="250">
            <template v-slot="{ row }">
              <div class="vehicle">
                <ul class="no-line">
                  <li>{{ row.make }} {{ row.model }}</li>
                  <li>{{ row.model_year }}</li>
                </ul>
                <ul>
                  <li>{{ row.series }}</li>
                  <li class="line" v-if="row.series">|</li>
                  <li v-if="row.color">{{ row.color }}</li>
                  <li class="line" v-if="row.color">|</li>
                  <li>{{ row.odometer ?? 0 }} {{ row.odometer_unit }}</li>
                </ul>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.CODE')"
            prop="code"
            sortable="custom"
            min-width="200"
          />

          <el-table-column
            :label="$t('COMMON.PRIORITY')"
            prop="priority"
            sortable="custom"
            min-width="200"
          >
            <template v-slot="{ row }">
              <span>
                <request-priority-badge :request="row" />
              </span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.STATUS')"
            prop="status"
            sortable="custom"
            min-width="200"
          >
            <template v-slot="{ row }">
              <span>
                <request-status-badge :request="row" />
              </span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('REQUESTS.REQUEST_TYPE')"
            prop="request_type"
            sortable="custom"
            min-width="200"
          >
            <template v-slot="{ row }">
              <span>
                {{
                  $t(`REQUESTS.REQUEST_TYPE_${row.request_type.toUpperCase()}`)
                }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('REQUESTS.EXPIRATION_DATE')"
            prop="expiration_time"
            sortable="custom"
            min-width="180"
          >
            <template v-slot="{ row }">
              {{ $timeZoneDateFormat(row.expiration_time, "LL") }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.CREATOR')"
            prop="price"
            sortable="custom"
            min-width="200"
          >
            <template v-slot="{ row }">
              <template v-if="!!row.creator">
                <object-link
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
                  :object="row.creator"
                />
                <span v-else>{{ $objectDenomination(row.creator) }}</span>
              </template>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('REQUESTS.UPDATED_AT')"
            prop="updated_at"
            sortable="custom"
            min-width="180"
          >
            <template v-slot="{ row }">
              {{ $timeZoneDateFormat(row.updated_at, "L LT") }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.TAGS')"
            prop="tags"
            sortable="custom"
            min-width="200"
          >
            <template v-slot="{ row }">
              <tags :tags="row.tags" @tagsUpdated="getList" />
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.LOCATIONS')"
            sortable="custom"
            min-width="220"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
          >
            <template v-slot="{ row }">
              <locations :locations="row.allowedLocations" />
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.ORGANIZATION')"
            sortable="custom"
            min-width="220"
            v-if="
              !filterOrganization &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
          >
            <template v-slot="{ row }">
              <organization :organization="row.organization" />
            </template>
          </el-table-column>

          <el-table-column fixed="right" min-width="120">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                :class="{
                  disabled: !$currentUserCan($permissions.PERM_VIEW_REQUESTS),
                }"
              >
                <a
                  type="text"
                  @click="viewRequest(row)"
                  class="table-action"
                  data-toggle="tooltip"
                >
                  <i
                    class="fa-light fa-arrow-up-right-and-arrow-down-left-from-center"
                  ></i>
                </a>
              </el-tooltip>

              <el-tooltip
                :content="$t('COMMON.DELETE')"
                placement="top"
                :class="{
                  disabled: !(
                    $currentUserCan($permissions.PERM_DELETE_REQUESTS) &&
                    row.status === REQUEST_STATUS_DRAFT
                  ),
                }"
              >
                <a
                  type="text"
                  @click="deleteRequest(row.id)"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                >
                  <i class="fa-light fa-trash-can"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="list-table-inner-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}

        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import {
  REQUEST_STATUS_DRAFT,
  requestStatusesOption,
  requestPrioritiesOption,
} from "@/constants/requests";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import UserSelector from "@/components/UserSelector.vue";
import Tags from "@/components/Tags.vue";
import TagsSelector from "@/components/TagsSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import RequestStatusBadge from "./RequestStatusBadge.vue";
import RequestPriorityBadge from "./RequestPriorityBadge.vue";
import CopyElement from "@/components/CopyElement.vue";

export default {
  name: "request-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    Tags,
    TagsSelector,
    LocationsSelector,
    NotificationSubscription,
    UserSelector,
    RequestStatusBadge,
    RequestPriorityBadge,
    CopyElement,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "organization id",
    },
    filterStatus: {
      type: String,
      default: null,
      description: "Status",
    },
    filterAssignedTo: {
      type: String,
      default: null,
      description: "Assigned To",
    },
    filterCreator: {
      type: String,
      default: null,
      description: "Creator",
    },
    filterPriority: {
      type: String,
      default: null,
      description: "Priority",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-updated_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      requests: [],
      loading: true,
      selectedOrganization: null,
      REQUEST_STATUS_DRAFT,
      selectedLocations: null,
      selectedTags: [],
      selectedStatus: null,
      showAdvancedFilter: false,
      requestStatusesOption: requestStatusesOption,
      selectedAssignedTo: null,
      selectedCreator: null,
      requestPrioritiesOption: requestPrioritiesOption,
      selectedPriority: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedTags: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterAssignedTo: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedAssignedTo: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterCreator: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedCreator: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterPriority: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedPriority: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,creator,allowedLocations,tags,vehicle",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }
        if (this.selectedTags) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              tags: this.selectedTags.map((item) => item.id),
            },
          };
        }
        if (this.filterStatus) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.filterStatus,
            },
          };
        }
        if (this.selectedStatus) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.selectedStatus,
            },
          };
        }
        if (this.filterAssignedTo) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              assignedTo: this.filterAssignedTo,
            },
          };
        }
        if (this.selectedAssignedTo) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              assignedTo: this.selectedAssignedTo,
            },
          };
        }
        if (this.filterCreator) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              creator: this.filterCreator,
            },
          };
        }
        if (this.selectedCreator) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              creator: this.selectedCreator,
            },
          };
        }
        if (this.filterPriority) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              priority: this.filterPriority,
            },
          };
        }
        if (this.selectedPriority) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              priority: this.selectedPriority,
            },
          };
        }

        await this.$store.dispatch("requests/list", params);
        this.requests = this.$store.getters["requests/list"];
        this.total = this.$store.getters["requests/listTotal"];
        this.loading = false;
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteRequest(id) {
      const confirmation = await swal.fire({
        title: this.$t("REQUESTS.DELETE_THIS_REQUEST"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch("requests/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("REQUESTS.REQUEST_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewRequest(request) {
      this.$emit("onViewRequest", request);
    },

    editRequest(request) {
      this.$emit("onEditRequest", request);
    },

    addRequest() {
      this.$emit("onAddRequest");
    },

    addQuickRequest() {
      this.$emit("onAddQuickRequest");
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
